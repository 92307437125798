








































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import StepperFooter from '@/modules/details/components/stepper-footer/stepper-footer.vue';
import { IUser } from '@/_core/models/user.model';
import { uploadPhoto } from '@/_shared/utils/upload-photo.util';
import { APP_CONFIG } from '@/_core/constants/app.config';

@Component({ name: 'portfolio', components: { StepperFooter } })
export default class Portfolio extends Vue {
  @Prop() readonly user: IUser;

  @Emit('onStepChanged') onStepChanged() {
    return { portfolio: this.images };
  }

  @Emit('onBack') onBack() {}

  public images: string[] = [];
  public isUploading = false;
  public mediaBase = APP_CONFIG.media.baseUrl;

  public async onFilesChange(evt: any) {
    if (evt.target.files.length > 8 || this.images.length + evt.target.files.length > 8) {
      alert('Maximum 8'); // TODO: handle error
      return;
    }
    this.isUploading = true;
    const promises = [];
    const { files } = evt.target;

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      promises.push(uploadPhoto(file));
    }

    const data: string[] = await Promise.all(promises);

    this.images = [...this.images, ...data];
    this.isUploading = false;
  }

  public onFormSubmit() {
    if (this.images.length) {
      this.onStepChanged();
    }
  }
}
