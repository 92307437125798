































































































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { VForm } from '@/_shared/types/v-form';
import { IUser } from '@/_core/models/user.model';
import StepperFooter from '@/modules/details/components/stepper-footer/stepper-footer.vue';
import { IVisualAppearance } from '@/modules/details/models/actor-stepper-form.model';
import { FormRules } from '@/_shared/utils/form-rules.util';
import ClUpload from '@/_shared/components/cl-upload/cl-upload.vue';

@Component({ name: 'visual-appearance', components: { ClUpload, StepperFooter } })
export default class VisualAppearance extends Vue {
  @Ref('formRef') readonly formRef!: VForm;
  @Prop() readonly user: IUser;

  @Emit('onStepChanged') onStepChanged() {
    return {
      ...this.form.fields,
      playing_age_min: this.form.fields.range[0],
      playing_age_max: this.form.fields.range[1],
    };
  }

  @Emit('onBack') onBack() {}

  public form: IVisualAppearance = {
    valid: false,
    fields: {
      hairColor: '',
      eyeColor: '',
      height: '',
      weight: '',
      mainPhoto: '',
      range: [20, 50],
    },
    rules: {
      hairColor: [FormRules.required('Hair Color')],
      eyeColor: [FormRules.required('Eye color')],
      height: [FormRules.required('Height')],
      weight: [FormRules.required('Weight')],
      mainPhoto: [FormRules.required('Photo')],
      range: [FormRules.required('Range')],
    },
  };

  public onFormSubmit() {
    if (this.formRef.validate()) {
      this.onStepChanged();
    }
  }
}
