



























































import { Component, Vue } from 'vue-property-decorator';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import analytic from '@/_core/services/analytic.service';

@Component({
  name: 'dashboard',
  components: { StripeCheckout },
})
export default class Dashboard extends Vue {
  public mounted() {
    analytic.track(`Navigation: Resume`);
  }
}
